<template>
  <a-modal
    width="700px"
    v-model:visible="visible"
    :title="
      boxNo
        ? $t('common.related_products') + '(' + boxNo + ')'
        : $t('common.related_products')
    "
    :cancelButtonProps="{ style: { display: 'none' } }"
    :closable="false"
    :maskClosable="false"
    :centered="true"
    :okText="$t('common.close')"
    :onOk="close"
  >
    <a-table
      :columns="columns"
      :data-source="data?.list"
      :pagination="false"
      :scroll="{ x: 500, y: 600 }"
      :loading="data.loading"
      :rowKey="
        (record, index) => {
          return index;
        }
      "
      size="small"
    >
      <template #product_info="{ record }">
        <a-row :gutter="[12, 12]" type="flex" align="middle">
          <a-col>
            <CFileDisplay :fileUrl="record.productImgUrl" />
          </a-col>
          <a-col flex="1">
            {{ record.productName }}
            <div>
              {{ record.productNo }}
            </div>
          </a-col>
        </a-row>
      </template>

      <template #count="{ record }">
        <span v-if="record.containerCount"
          >{{ record.containerCount }} Unit</span
        >
        <span v-else>-</span>
      </template>
    </a-table>
  </a-modal>
</template>

<script>
import { defineComponent, reactive,watchEffect, watch } from "vue";
import { Modal, Table, Row, Col } from "ant-design-vue";
import { useI18n } from "vue-i18n/index";
import CFileDisplay from "../components/CFileDisplay.vue";
import { getBoxOfProductsByWhboxId } from "../../api/modules/common/index";
export default defineComponent({
  name: "CProductDetailModal",
  components: {
    CFileDisplay,
    AModal: Modal,
    ATable: Table,
    ARow: Row,
    ACol: Col,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    whboxId: {
      type: String,
      required: true,
    },
    boxNo: {
      type: String,
      default: "",
    },
    emits: ["update:visible"],
  },
  setup(props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });

    const columns = [
      {
        width: 300,
        title: () => vueI18n.t("warehouse.product_info"),
        slots: {
          customRender: "product_info",
        },
      },
       {
        title: 'SESKU',
        dataIndex: "seSku",
        width: 100,
      },
      {
        width: 100,
        title: () => vueI18n.t("warehouse.product_quantity"),
        slots: {
          customRender: "count",
        },
      },
    ];

    const data = reactive({
      loading: false,
      list: [],
    });

    // watch(
    //   () => props.visible,
    //   (newVal, oldV) => {
    //     console.log(1);
    //     console.log(newVal);
    //     console.log(oldV);
    //     if (newVal) {
    //       handleGet();
    //     }
    //   }
    // );

  

    const handleGet = () => {
      data.loading = true;
      getBoxOfProductsByWhboxId(props.whboxId)
        .then(({ result }) => {
          data.list = result;
          data.loading = false;
        })
        .catch(() => {
          data.loading = false;
        });
    };

    watchEffect(() => {
      if (props.visible) {
        handleGet();
      }
    });

    const close = () => {
      emit("update:visible", false);
    };

    return {
      columns,
      close,
      data,
    };
  },
});
</script>
