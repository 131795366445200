<template>
  <Content>
    <template #contentBody>
      <a-spin :spinning="loading">
        <a-row>
          <a-col :span="24">
            <a-steps :current="1" style="max-width: 1800px" size="small">
              <a-step :title="$t('warehouse.select_out_warehouse_way')" />
              <a-step :title="$t('warehouse.add_box_info')" />
              <a-step :title="$t('warehouse.confirm_out_plan')" />
              <a-step :title="$t('common.completed')" />
            </a-steps>
          </a-col>
        </a-row>
        <!-- 仓库信息 -->
        <div class="inputs mt-3">
          <a-row :gutter="[0, 12]">
            <a-col :span="24">
              <label>{{ $t("warehouse.out_warehouse_no") }}:</label>
              {{ plan.inventoryWarehouseAddress.warehouseNo }}
            </a-col>
            <a-col :span="24">
              <label>{{ $t("warehouse.warehouse_address") }}:</label>
              {{ getAddress(plan.inventoryWarehouseAddress) }}
              <span v-if="plan.inventoryWarehouseAddress?.linkMan"
                >({{ getLinkman(plan.inventoryWarehouseAddress) }})</span
              >
            </a-col>
            <a-col :span="24">
              <label>{{ $t("warehouse.out_warehouse_type") }}:</label>
              {{
                $t(
                  $enumLangkey(
                    "outWarehouseType",
                    plan.selectedOutWarehouseType
                  )
                )
              }}
            </a-col>

            <!-- 目标仓库代码(仓库间调拨有) -->
            <a-col
              v-if="
                plan.selectedOutWarehouseType ==
                  outWarehouseTypeEnum.betweenWarehouseAllocate ||
                plan.selectedOutWarehouseType ==
                  outWarehouseTypeEnum.replaceSendWarehouseEntry
              "
              :span="24"
            >
              <label>{{ $t("warehouse.target_warehouse_code") }}:</label>
              {{ plan.targetAddress.warehouseNo }}
            </a-col>

            <!-- FBA仓库代码 -->
            <a-col
              v-if="
                plan.selectedOutWarehouseType == outWarehouseTypeEnum.amazonFba
              "
              :span="24"
            >
              <label>{{ $t("warehouse.fba_warehouse_code") }}:</label>
              {{ plan.targetAddress.name }}
            </a-col>
            <!-- FBm仓库代码 -->
            <a-col
              v-if="
                plan.selectedOutWarehouseType ==
                outWarehouseTypeEnum.mercadolibre
              "
              :span="24"
            >
              <label>{{ $t("warehouse.fbm_warehouse_code") }}:</label>
              {{ plan.targetAddress.name }}
            </a-col>

            <!-- 发至-->
            <a-col
              v-if="
                plan.selectedOutWarehouseType ==
                outWarehouseTypeEnum.otherAddress
              "
              :span="24"
            >
              <label>{{ $t("warehouse.destination") }}:</label>
              {{ plan.targetAddress.name }}
            </a-col>
            <!-- 运送地址(本地配送仓没有) -->
            <a-col
              v-if="
                plan.selectedOutWarehouseType !=
                  outWarehouseTypeEnum.replaceSendWarehouseEntry &&
                plan.selectedOutWarehouseType !=
                  outWarehouseTypeEnum.destruction
              "
              :span="24"
            >
              <label>{{ $t("warehouse.transport_goods_address") }}:</label>
              {{ getAddress(plan.targetAddress) }}
              <span v-if="plan.targetAddress.telephone">
                (+{{ plan.targetAddress.telephoneCountryCode }}
                {{ plan.targetAddress.telephone }})
              </span>
              <a-tag
                v-if="
                  plan.targetAddress.countryId !=
                  plan.inventoryWarehouseAddress.countryId
                "
                color="warning"
                >{{ $t("warehouse.international_transport") }}</a-tag
              >
              <template
                v-if="
                  plan.selectedOutWarehouseType ==
                  outWarehouseTypeEnum.betweenWarehouseAllocate
                "
              >
                <span class="ml-5" v-if="plan.targetAddress"
                  >{{ $t("logistics.contacts") }}:
                  {{ getLinkman(plan.targetAddress) }}</span
                >
              </template>
            </a-col>
          </a-row>

          <!-- 运输编号和追踪号(FBA和fbm独有) -->
          <form
            v-if="
              plan.selectedOutWarehouseType == outWarehouseTypeEnum.amazonFba ||
              plan.selectedOutWarehouseType == outWarehouseTypeEnum.mercadolibre
            "
            class="mt-3"
          >
            <a-form-item>
              <template #label>
                <span
                  v-if="
                    plan.selectedOutWarehouseType ==
                    outWarehouseTypeEnum.amazonFba
                  "
                >
                  {{ $t("warehouse.amazon_shipping_number") }}
                </span>
                <span v-else>{{ $t("warehouse.seller_id") }}</span>
              </template>

              <a-input
                v-model:value="plan.amazonShippingNumber"
                style="max-width: 250px"
              />
            </a-form-item>

            <a-form-item>
              <template #label>
                <span
                  v-if="
                    plan.selectedOutWarehouseType ==
                    outWarehouseTypeEnum.amazonFba
                  "
                >
                  {{ $t("warehouse.shipment_tracking_number") }}
                </span>
                <span v-else>{{ $t("warehouse.warehousing_number") }}</span>
              </template>

              <a-input
                v-model:value="plan.shipmentTrackingNumber"
                style="max-width: 250px"
              />
            </a-form-item>
          </form>
        </div>

        <!-- 添加箱号按钮 -->
        <div class="mb-2 mt-2">
          <a-button type="primary" @click="handleShowModalAddBoxNo" ghost>
            {{ $t("warehouse.add_box_no") }}
            ({{ plan.inventoryWarehouseAddress.warehouseNo }})
          </a-button>

          <a-upload
            class="ml-4"
            v-if="
              plan.selectedOutWarehouseType == outWarehouseTypeEnum.amazonFba ||
              plan.selectedOutWarehouseType ==
                outWarehouseTypeEnum.mercadolibre ||
              plan.selectedOutWarehouseType == outWarehouseTypeEnum.otherAddress
            "
            :customRequest="handleBatchUploadLabels"
            accept=".pdf"
            :showUploadList="false"
          >
            <a-button
              :disabled="!plan?.tableList || plan?.tableList?.length == 0"
            >
              <upload-outlined></upload-outlined>
              {{ $t("warehouse.batch_upload_of_container_labels") }}
            </a-button>
          </a-upload>
        </div>
        <!-- 列表 -->
        <div class="table">
          <a-table
            :columns="column"
            :data-source="plan.tableList"
            size="small"
            :expandIconAsCell="false"
            :expandIconColumnIndex="2"
            :loading="plan.loading"
            :pagination="{
              defaultPageSize: defaultPageSize,
              hideOnSinglePage: true,
              current: currentPageIndex,
              position: 'both',
            }"
            @change="
              (page) => {
                currentPageIndex = page.current;
              }
            "
          >
            <template #boxNo="{ record }">
              <a-row :gutter="[12, 12]">
                <a-col>
                  <div>{{ record.boxNo }}</div>
                  <div>
                    <small>{{ record.selfBoxNo ?? "-" }}</small>
                  </div>
                </a-col>
                <a-col>
                  <CTagMixedOrSingle
                    :productKindCount="record.productKindCount * 1"
                  ></CTagMixedOrSingle>
                </a-col>
              </a-row>
            </template>

            <template #containerCount="{ record }">
              <div v-if="record.containerCount">
                {{ record.containerCount }} Unit
              </div>
              <div v-else>-</div>
            </template>

            <template #out_warehouse_info="{ record }">
              <a-row>
                <a-col>
                  {{ $t("warehouse.out_warehouse_encasement_size") }}:
                </a-col>
                <a-col>
                  <span v-if="record.checkContainerLength">
                    {{ record.checkContainerLength }}x{{
                      record.checkContainerWidth
                    }}x{{ record.checkContainerHeight }} cm</span
                  >
                  <span v-else>-</span>
                </a-col>
              </a-row>
              <a-row>
                <a-col>
                  {{ $t("warehouse.out_warehouse_encasement_weight") }}:
                </a-col>
                <a-col>
                  <span v-if="record.checkContainerWeight">
                    {{ gToKg(record.checkContainerWeight) }} kg</span
                  >
                  <span v-else>-</span>
                </a-col>
              </a-row>
            </template>

            <template #labelDispose>
              <a-dropdown>
                <span @click.prevent>
                  {{ $t("warehouse.label_serve") }}
                  <DownOutlined />
                </span>
                <template #overlay>
                  <a-menu>
                    <a-menu-item
                      v-for="(value, key) in labelDisposeEnum"
                      :key="key"
                      :value="value"
                      @click="handelSetGlobalLabelDispose(value)"
                    >
                      {{ $t($enumLangkey("labelDispose", value)) }}
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </template>

            <template #labelDisposeCustom="{ record }">
              <a-select
                v-model:value="record.selectedLabelDispose"
                :placeholder="$t('common.please_select')"
                style="min-width: 120px; max-width: 200px"
              >
                <a-select-option
                  v-for="(value, key, index) in labelDisposeEnum"
                  :key="key"
                  :value="value"
                  :data-index="index"
                >
                  {{ $t($enumLangkey("labelDispose", value)) }}
                </a-select-option>
              </a-select>
            </template>

            <template #lableServeCustom="{ record }">
              <div class="label-serve">
                <span>{{ $t("warehouse.outer_box_label") }}</span>
                <a-upload
                  name="box label"
                  accept=".pdf,.jpg,.jpeg,.png"
                  list-type="picture-card"
                  :customRequest="handleUpdate"
                  :data="{ rowData: record }"
                  :showUploadList="false"
                >
                  <div v-if="record.lableImgSrc" style="position: relative">
                    <CFileDisplay
                      @click.stop
                      :file-url="record.lableImgSrc"
                      :use-local="true"
                    ></CFileDisplay>

                    <a
                      style="
                        width: 100%;
                        bottom: 0px;
                        position: absolute;
                        left: 0px;
                        text-align: center;
                        line-height: 17px;
                        margin: auto;
                        background: rgba(0, 0, 0, 0.5);
                        border-radius: 0 0 4px 4px;
                      "
                    >
                      <small>
                        <UploadOutlined /> {{ $t("common.upload") }}
                      </small>
                    </a>
                  </div>
                  <div v-else>
                    <loading-outlined
                      v-if="record.uploadLoading"
                    ></loading-outlined>
                    <plus-outlined v-else></plus-outlined>
                  </div>
                </a-upload>
                <span v-if="record.lableImgCode">{{
                  record.lableImgCode
                }}</span>
              </div>
            </template>

            <template #operateCustom="{ record }">
              <div class="ant-form-inline">
                <a-button @click="handleDelete(record)" type="primary" ghost>{{
                  $t("common.remove")
                }}</a-button>
              </div>
            </template>

            <template #expandIcon="{ expanded, record, onExpand }">
              <a-row :gutter="[12, 12]">
                <a-col>
                  <CHaveProductInformation
                    :disabled="
                      !record.productRls || record.productRls?.length == 0
                    "
                    :open="expanded"
                    @click="(e) => onExpand(record, e)"
                  />
                </a-col>
                <a-col
                  v-if="
                    plan.selectedOutWarehouseType ==
                      outWarehouseTypeEnum.replaceSendWarehouseEntry &&
                    (!record.productRls || record.productRls?.length == 0)
                  "
                >
                  <a-button
                    type="primary"
                    ghost
                    @click="handleShowAddProductModal(record)"
                  >
                    {{ $t("warehouse.improve_product_data") }}
                  </a-button>
                </a-col>
              </a-row>
            </template>
            <template #expandedRowRender="{ record }">
              <a-table
                :columns="innerColumns"
                :data-source="record.productRls"
                :pagination="false"
                :row-key="(record, index) => index"
                size="small"
              >
                <template #product_info="{ record }">
                  <a-row :gutter="[12, 12]">
                    <a-col>
                      <CFileDisplay
                        :fileUrl="record.productImgUrl || record.imgUrl"
                      />
                    </a-col>
                    <a-col>
                      {{ record.productName }}
                      <div>
                        {{ record.productNo }}
                      </div>
                    </a-col>
                  </a-row>
                </template>
                <template #count="{ record }">
                  {{ record.containerCount }} Unit
                </template>
              </a-table>
            </template>

            <template #footer>
              <a-row type="flex" justify="end" class="mr-5">
                <a-col>
                  {{ $t("common.total") }}: {{ plan.tableList.length
                  }}{{ $t("warehouse.box") }}
                </a-col>
              </a-row>
            </template>
          </a-table>
        </div>

        <!-- 打托服务创建出库计划按钮 -->
        <div class="footer-buttons mr-5 mt-3">
          <a-row
            v-if="
              plan.selectedOutWarehouseType !=
                outWarehouseTypeEnum.replaceSendWarehouseEntry &&
              plan.selectedOutWarehouseType != outWarehouseTypeEnum.destruction
            "
            type="flex"
            justify="start"
          >
            <a-col>
              <a-form-item :label="$t('warehouse.hit_torr_serve')">
                <a-select
                  v-model:value="plan.hitTorrServe"
                  style="min-width: 200px"
                >
                  <a-select-option value="0">
                    {{ $t("warehouse.not_required") }}
                  </a-select-option>
                  <a-select-option
                    v-for="item in hitTorrServes"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }} {{ item.size
                    }}{{ $t("warehouse.tray_volume_restriction") }}:
                    {{ item.volumeRestriction
                    }}{{ $t("warehouse.tray_weight_limit") }}:
                    {{ item.weightLimit }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row type="flex" justify="end">
            <a-col class="mr-3 mb-5">
              <a-button type="primary" @click="handleBack" ghost>{{
                $t("common.backtrack")
              }}</a-button>
            </a-col>
            <a-col>
              <a-button
                type="primary"
                :loading="buttonCreatePlanLoading"
                @click="handleShowAddNameModal"
                >{{ $t("warehouse.created_plan") }}</a-button
              >
            </a-col>
          </a-row>
        </div>
        <!-- 添加出库计划的名称 -->
        <a-modal
          v-model:visible="isShowModalAddPlanName"
          :title="$t('warehouse.add_plan_name')"
          :footer="null"
          :centered="true"
        >
          <div>
            <input type="text" class="display-none" />
            <a-row type="flex" justify="center" align="middle">
              <a-col>
                <p class="height-100">
                  {{ $t("warehouse.please_input_plan_name") }}
                </p>
              </a-col>
            </a-row>
            <a-row type="flex" justify="center" align="middle">
              <a-col :span="15">
                <a-input
                  v-model:value="plan.outPlanName"
                  required="true"
                  ref="refInputAddPlanName"
                  @focus="handleFocusSelectValue($event)"
                  @pressEnter="handleCreatePlan"
                />
              </a-col>
            </a-row>
          </div>
          <a-row class="mt-4 mb-1" type="flex" justify="center" align="middle">
            <a-col class="mr-4">
              <a-button
                :loading="addPlanNameModalOkLoading"
                @click="isShowModalAddPlanName = false"
                >{{ $t("common.cancel") }}</a-button
              >
            </a-col>
            <a-col>
              <a-button
                type="primary"
                :loading="addPlanNameModalOkLoading"
                @click="handleCreatePlan"
                >{{ $t("warehouse.created_plan") }}</a-button
              >
            </a-col>
          </a-row>
        </a-modal>

        <!-- 按箱号添加 -->
        <a-modal
          width="1200px"
          v-model:visible="isShowModalAddBoxNo"
          :centered="true"
          :maskClosable="false"
          :ok-button-props="{ hidden: true }"
          :cancelText="$t('common.close')"
          :title="
            $t('warehouse.add_box_no') +
            '(' +
            plan.inventoryWarehouseAddress.warehouseNo +
            ')'
          "
        >
          <a-form layout="inline">
            <a-form-item class="mb-1" :label="$t('warehouse.product_info')">
              <SearchProduct ref="refSearchProductForAddBox"></SearchProduct>
            </a-form-item>
            <a-form-item class="mb-1" :label="$t('warehouse.box_no')">
              <a-input
                v-model:value="addBoxNoModal.searchBoxKey"
                allow-clear
                :placeholder="$t('warehouse.please_enter_box_no')"
                style="width: 250px"
              />
            </a-form-item>
            <a-form-item
              class="mb-1"
              :label="$t('warehouse.in_warehouse_time')"
            >
              <a-range-picker
                style="width: 200px"
                v-model:value="addBoxNoModal.dataRange"
              />
            </a-form-item>
            <a-form-item class="mb-1">
              <a-button
                type="primary"
                :loading="modalListLoading"
                @click="hanldeSearchBoxList"
                >{{ $t("common.query") }}</a-button
              >
            </a-form-item>
          </a-form>
          <a-table
            :columns="modalColumns"
            :data-source="addBoxNoModal.boxList"
            :scroll="{ x: 500, y: 600 }"
            size="small"
            :pagination="false"
            :loading="modalListLoading"
            :rowKey="(record, index) => index"
          >
            <template #boxNo="{ record }">
              <a-row :gutter="[12, 12]">
                <a-col>
                  <div>{{ record.boxNo }}</div>
                  <div>
                    <small>{{ record.selfBoxNo ?? "-" }}</small>
                  </div>
                </a-col>
                <a-col>
                  <CTagMixedOrSingle
                    :productKindCount="record.productKindCount * 1"
                  ></CTagMixedOrSingle>
                </a-col>
              </a-row>
            </template>

            <template #related_products="{ record }">
              <CHaveProductInformation
                :disabled="record.productKindCount < 1"
                @click="(e) => handleClickProductIoc(record)"
              />
            </template>

            <template #inWarehouseTimeCustom="{ record }">
              {{ $filters.utcToCurrentTime(record.inWarehouseTime) }}
            </template>

            <template #addButtonCustom="{ record }">
              <a-button v-if="!record.canChoose" disabled>{{
                $t("warehouse.temporarily_unavailable")
              }}</a-button>
              <a-button
                v-else-if="record.canChoose && !isExistsBox(record)"
                @click="handleAddBoxNoToTable(record)"
                type="primary"
                ghost
                >{{ $t("common.add") }}</a-button
              >
              <a-button v-else disabled>{{ $t("common.added") }}</a-button>
            </template>
          </a-table>
          <a-row type="flex" justify="space-around" align="middle" class="mt-3">
            <a-col>
              <CPager
                @handlePage="handlePage"
                :showLessItems="true"
                :pageSizeOptions="['5', '10', '30']"
                :page-data="pageData"
              ></CPager>
            </a-col>
          </a-row>
        </a-modal>

        <!-- 完善产品数据 -->
        <a-modal
          width="800px"
          v-model:visible="addProductModal.visible"
          :centered="true"
          :maskClosable="false"
          :title="
            $t('warehouse.improve_product_data') +
            '(' +
            addProductModal.record?.boxNo +
            ')'
          "
          :footer="null"
        >
          <a-form layout="inline">
            <a-form-item class="mb-1">
              <SearchProduct
                ref="refSearchProduct"
                v-model:productData="addProductModal.productData"
              ></SearchProduct>
            </a-form-item>
            <a-form-item class="mb-1">
              <a-button
                type="primary"
                :disabled="!addProductModal.productData"
                ghost
                @click="handleAdd"
                >{{ $t("common.add") }}</a-button
              >
            </a-form-item>
          </a-form>
          <a-table
            :columns="addProductModalColumns"
            :data-source="addProductModal.list"
            :scroll="{ x: 500, y: 600 }"
            size="small"
            :rowKey="
              (record, index) => {
                return index;
              }
            "
            :pagination="false"
          >
            <template #product_info="{ record }">
              <a-space>
                <div class="table-list-img-common">
                  <c-image :src="record?.imgUrl" />
                </div>
                <div>
                  <div>{{ record?.productName }}</div>
                  <div>{{ record?.productNo }}</div>
                </div>
              </a-space>
            </template>

            <template #number="{ record }">
              <a-input-number
                v-model:value="record.containerCount"
                :precision="0"
                :min="1"
              ></a-input-number>
              Unit
            </template>

            <template #remove="{ record }">
              <div class="ant-form-inline">
                <a-button @click="handleAddProductModalRemove(record)">{{
                  $t("common.remove")
                }}</a-button>
              </div>
            </template>
          </a-table>
          <a-row
            type="flex"
            justify="center"
            align="middle"
            class="mt-3"
            :gutter="[48, 12]"
          >
            <!-- <a-col>
            <a-button @click="addProductModal.visible = false">{{
              $t("common.cancel")
            }}</a-button>
          </a-col> -->
            <a-col>
              <a-button type="primary" @click="handleConfirmAddProductModal">{{
                $t("common.confirm")
              }}</a-button>
            </a-col>
          </a-row>
        </a-modal>

        <div v-if="addBoxNoModal.productDetail.whboxId">
          <CProductDetailModal
            v-model:visible="addBoxNoModal.productDetail.visible"
            :whboxId="addBoxNoModal.productDetail.whboxId"
            :boxNo="addBoxNoModal.productDetail.boxNo"
          ></CProductDetailModal>
        </div>
      </a-spin>
    </template>
  </Content>
</template>

<script>
import { reactive, toRefs, onMounted, ref, nextTick } from "vue";
import Content from "../../components/Content.vue";
import {
  Row,
  Col,
  Steps,
  Tag,
  Dropdown,
  Menu,
  Table,
  Input,
  Upload,
  InputNumber,
  DatePicker,
  Select,
  Button,
  Form,
  Modal,
  Space,
  message,
  Spin,
} from "ant-design-vue";
import {
  outWarehouseType as outWarehouseTypeEnum,
  labelDispose as labelDisposeEnum,
} from "../../../enum/enum.json";
import { useI18n } from "vue-i18n/index";
import CPager from "../../components/CPager.vue";
import CHaveProductInformation from "../../components/CHaveProductInformation.vue";
import CTagMixedOrSingle from "../../components/CTagMixedOrSingle.vue";
import {
  getHitTorrServes,
  getBoxList,
  createOutPlan,
  getOutProductBoxInfos,
  addProductToBox,
} from "../../../api/modules/storage/index";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import { useTab } from "../../../hooks/tabs/index";
import {
  getAddressByLanguageV2,
  createAutoPlanName,
  getLinkman,
  currentTimeToUtc,
  gToKg,
  cmCubicToM,
} from "../../../utils/general";
import { useStore } from "vuex";
import {
  getObjectSessionStorage,
  removeObjectSessionStorage,
} from "../../../utils/store";
import { outPlanKeys } from "../../../consts/index";
import SearchProduct from "../../components/SearchProduct.vue";
import CImage from "../../components/CImage.vue";
import { uploadImg } from "../../../api/modules/common/index";
import CFileDisplay from "../../components/CFileDisplay.vue";
import CProductDetailModal from "../../components/CProductDetailModal.vue";
import { batchUploadLabels } from "@/api/modules/storage/index";
import { maxUploadFileSizeByte } from "../../../utils/common";

export default {
  name: "storage_add_box_info",
  components: {
    CProductDetailModal,
    CHaveProductInformation,
    CTagMixedOrSingle,
    SearchProduct,
    Content,
    CPager,
    CImage,
    ARangePicker: DatePicker.RangePicker,
    ATag: Tag,
    ASteps: Steps,
    AStep: Steps.Step,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AInput: Input,
    AInputNumber: InputNumber,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    AFormItem: Form.Item,
    AForm: Form,
    AModal: Modal,
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    AUpload: Upload,
    ASpace: Space,
    ASpin: Spin,
    CFileDisplay,
  },
  setup() {
    const i18n = useI18n({ useScope: "global" });
    const router = useRouter();
    const refInputAddPlanName = ref();
    const refSearchProductForAddBox = ref();
    const refSearchProduct = ref();

    const { delVisitedRoute, visitedRoutes } = useTab();
    const { getters } = useStore();

    const innerColumns = [
      { width: 60 },
      {
        title: () => i18n.t("warehouse.product_info"),
        slots: {
          customRender: "product_info",
        },
      },
      {
        title: "SESKU",
        dataIndex: "seSku",
      },
      {
        title: () => i18n.t("common.count"),
        slots: {
          customRender: "count",
        },
      },
    ];

    const modalColumns = [
      {
        title: () => i18n.t("warehouse.box_no"),
        slots: {
          customRender: "boxNo",
        },
        width: 100,
      },
      {
        title: () => i18n.t("common.related_products"),
        slots: {
          customRender: "related_products",
        },
        width: 100,
      },
      {
        title: () => i18n.t("warehouse.in_warehouse_time"),
        slots: {
          customRender: "inWarehouseTimeCustom",
        },
        width: 100,
      },
      {
        title: "",
        slots: {
          customRender: "addButtonCustom",
        },
        fixed: "right",
        width: 100,
      },
    ];

    const columns = [
      {
        title: () => i18n.t("warehouse.serial_number"),
        dataIndex: "serialNumber ",
        customRender: ({ text, record, index }) => {
          return record.key;
        },
        width: 60,
      },
      {
        title: () => i18n.t("warehouse.box_no"),
        slots: {
          customRender: "boxNo",
        },
        width: 150,
      },
      {
        title: "",
        width: 100,
      },
      {
        title: () => i18n.t("warehouse.encasement_count"),
        slots: {
          customRender: "containerCount",
        },
        width: 120,
      },
      {
        title: () => i18n.t("warehouse.out_warehouse_info"),
        slots: {
          customRender: "out_warehouse_info",
        },
        width: 200,
      },
      {
        title: () => i18n.t("warehouse.label_serve_paid"),
        dataIndex: "lableServe",
        slots: {
          customRender: "lableServeCustom",
        },
        fixed: "right",
        width: 200,
      },
      {
        dataIndex: "labelDispose",
        slots: {
          title: "labelDispose",
          customRender: "labelDisposeCustom",
        },
        fixed: "right",
        width: 100,
      },
      {
        title: "",
        dataIndex: "operate",
        slots: {
          customRender: "operateCustom",
        },
        fixed: "right",
        width: 80,
      },
    ];

    const state = reactive({
      loading: false,
      uploadLoading: false,
      buttonCreatePlanLoading: false,
      modalListLoading: false,
      addPlanNameModalOkLoading: false,

      isShowModalAddBoxNo: false,
      isShowModalAddPlanName: false,

      hitTorrServes: [],
      column: [],

      globalSelectedLabelDispose: null, //header中批量设置SE标签

      plan: {
        inventoryWarehouseAddress: {
          //出库仓库
          warehouseType: "",
          warehouseNo: "",
          warehouseId: "", //仓库id
        },
        targetAddress: {
          //目标库
          warehouseType: "",
          warehouseNo: "",
          id: "", //仓库id
        },
        selectedOutWarehouseType: "",
        amazonShippingNumber: "",
        shipmentTrackingNumber: "",
        outPlanName: "",
        hitTorrServe: "0",
        tableList: [],
        totalBox: 0,
      },

      addBoxNoModal: {
        boxList: [],
        searchBoxKey: "",
        dataRange: [],
        productDetail: {
          visible: false,
          whboxId: null,
          boxNo: null,
        },
      },
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 5,
      },
    });

    const isExistsBox = (record) => {
      return state.plan.tableList?.findIndex((x) => x.id == record.id) >= 0;
    };

    const getAddress = (address) => {
      return getAddressByLanguageV2(address, getters.language);
    };

    const handleFocusSelectValue = (event) => {
      event.currentTarget.select();
    };

    const funcFocusInput = (ref) => {
      nextTick(() => {
        ref.value.focus();
      });
    };

    const handleDelete = (record) => {
      let temp = state.plan.tableList.filter((item) => item.id != record.id);
      temp.forEach((x, index) => (x.key = index + 1));
      state.plan.tableList = temp;
      message.success(i18n.t("common.remove_success"));
    };

    const handleBack = async () => {
      delVisitedRoute(router.currentRoute.value);
      router.push({ path: "/storage/selectoutWay" });
    };

    const handleShowAddNameModal = async () => {
      if (!_funcCheckForm()) {
        return false;
      }
      state.plan.outPlanName = createAutoPlanName();
      state.isShowModalAddPlanName = true;
      funcFocusInput(refInputAddPlanName);
    };

    const handleCreatePlan = async () => {
      if (state.plan.outPlanName?.trim()?.length == 0) {
        message.error(
          i18n.t("common.p0_is_required", [i18n.t("warehouse.plan_name")])
        );
        return false;
      }

      let saveTableList = state.plan.tableList.map((x) => {
        return {
          whBoxId: x.id,
          productLabelProcessing: x.selectedLabelDispose,
          serviceLabelImgUrl: x.lableImgSrc,
          serviceLabelNo: x.lableImgCode,
        };
      });
      state.addPlanNameModalOkLoading = true;
      let pra = {
        planName: state.plan.outPlanName,
        from: {
          warehouseType: state.plan.inventoryWarehouseAddress.warehouseType,
          warehouseId: state.plan.inventoryWarehouseAddress.warehouseId,
        },
        outWarehouseType: state.plan.selectedOutWarehouseType,
        toWarehouse: null,
        to: null,
        toFbaWarehouse: null,
        fbaAmazonTransNo: state.plan.amazonShippingNumber,
        fbaTranceNo: state.plan.shipmentTrackingNumber,
        items: saveTableList,
        daycareServiceId: state.plan.hitTorrServe,
      };

      if (
        state.plan.selectedOutWarehouseType ==
          outWarehouseTypeEnum.betweenWarehouseAllocate ||
        state.plan.selectedOutWarehouseType ==
          outWarehouseTypeEnum.replaceSendWarehouseEntry
      ) {
        pra.toWarehouse = {
          warehouseType: state.plan.targetAddress.warehouseType,
          warehouseId: state.plan.targetAddress.id,
        };
      } else if (
        state.plan.selectedOutWarehouseType == outWarehouseTypeEnum.destruction
      ) {
        pra.toWarehouse = null;
      }
      else if (
        state.plan.selectedOutWarehouseType ==
          outWarehouseTypeEnum.mercadolibre ||
        state.plan.selectedOutWarehouseType == outWarehouseTypeEnum.amazonFba
      ) {
        pra.toFbaWarehouse = {
          warehouseId: state.plan.targetAddress.id,
        };
      } else if (
        state.plan.selectedOutWarehouseType == outWarehouseTypeEnum.otherAddress
      ) {
        pra.to =
          Object.keys(state.plan.addressData)?.length == 0
            ? null
            : state.plan.addressData;
      }

      createOutPlan(pra)
        .then((res) => {
          let planId = res.result;
          message.success(i18n.t("common.create_success"));
          delVisitedRoute(router.currentRoute.value);
          router.push({ path: "/storage/confirmoutplan/" + planId });
        })
        .finally(() => {
          state.addPlanNameModalOkLoading = false;
          state.isShowModalAddPlanName = false;
        });
    };

    const _funcCheckForm = () => {
      const outWarehouseType = state.plan.selectedOutWarehouseType;
      if (state.plan.tableList?.length == 0) {
        message.error(i18n.t("warehouse.please_add_the_container_number"));
        return false;
      } else if (
        outWarehouseType == outWarehouseTypeEnum.replaceSendWarehouseEntry
      ) {
        let res = state.plan.tableList.find((x) => !x.selectedLabelDispose);
        if (res) {
          currentPageIndex.value = Math.ceil(res.key / defaultPageSize.value);
          message.error(
            i18n.t("common.p0_is_required", [i18n.t("warehouse.label_serve")])
          );
          return false;
        }

        let r = state.plan.tableList.find((x) => x.productRls?.length == 0);
        if (r) {
          currentPageIndex.value = Math.ceil(r.key / defaultPageSize.value);
          message.error(
            i18n.t("common.p0_is_required", [
              i18n.t("warehouse.improve_product_data"),
            ])
          );
          return false;
        }
      } else if (outWarehouseType == outWarehouseTypeEnum.destruction) {
        // Nothing
      } else if (outWarehouseType == outWarehouseTypeEnum.amazonFba) {
        if (!state.plan.amazonShippingNumber) {
          message.error(
            i18n.t("common.p0_is_required", [
              i18n.t("warehouse.amazon_shipping_number"),
            ])
          );
          return false;
        }

        if (!state.plan.shipmentTrackingNumber) {
          message.error(
            i18n.t("common.p0_is_required", [
              i18n.t("warehouse.shipment_tracking_number"),
            ])
          );
          return false;
        }

        let res = state.plan.tableList.find((x) => !x.lableImgSrc);
        if (res) {
          currentPageIndex.value = Math.ceil(res.key / defaultPageSize.value);
          message.error(
            i18n.t("common.p0_is_required", [i18n.t("warehouse.label_serve")])
          );
          return false;
        }
      } else if (outWarehouseType == outWarehouseTypeEnum.mercadolibre) {
        if (!state.plan.amazonShippingNumber) {
          message.error(
            i18n.t("common.p0_is_required", [i18n.t("warehouse.seller_id")])
          );
          return false;
        }

        if (!state.plan.shipmentTrackingNumber) {
          message.error(
            i18n.t("common.p0_is_required", [
              i18n.t("warehouse.warehousing_number"),
            ])
          );
          return false;
        }

        let res = state.plan.tableList.find((x) => !x.lableImgSrc);
        if (res) {
          currentPageIndex.value = Math.ceil(res.key / defaultPageSize.value);
          message.error(
            i18n.t("common.p0_is_required", [i18n.t("warehouse.label_serve")])
          );
          return false;
        }
      }
      return true;
    };

    const getCacheData = async () => {
      let cacheData = getObjectSessionStorage(outPlanKeys.createOutPlanData);
      if (cacheData) {
        Object.assign(state.plan, cacheData);
        if (
          cacheData.addressData &&
          cacheData.selectedOutWarehouseType ==
            outWarehouseTypeEnum.otherAddress
        ) {
          state.plan.targetAddress = cacheData.addressData;
        }
      }
    };

    const funcHitTorrServes = () => {
      let pra = {
        warehouseId: state.plan.inventoryWarehouseAddress.warehouseId,
        isPallet: true,
        isActive: true,
      };
      getHitTorrServes(pra).then((res) => {
        state.hitTorrServes = res.result.map((item) => {
          let size = null;
          if (
            item.packMaterialLength &&
            item.packMaterialWidth &&
            item.packMaterialHeight
          ) {
            size =
              item.packMaterialLength +
              "x" +
              item.packMaterialWidth +
              "x" +
              item.packMaterialHeight +
              " cm";
          }
          return {
            id: item.id,
            name: item.packMaterialName ? item.packMaterialName + " , " : "",
            size: size ? size + " , " : "",
            volumeRestriction: item.packMaterialVolume
              ? cmCubicToM(item.packMaterialVolume) + " m³, "
              : " - , ",
            weightLimit: item.packBearingQuantity
              ? gToKg(item.packBearingQuantity) + " kg "
              : "- ",
          };
        });
      });
    };

    const getColumn = () => {
      const outWarehouseType = state.plan.selectedOutWarehouseType;
      let newColumns;
      if (outWarehouseType == outWarehouseTypeEnum.betweenWarehouseAllocate) {
        newColumns = columns.filter(
          (x) => x.dataIndex != "lableServe" && x.dataIndex != "labelDispose"
        );
      } else if (
        outWarehouseType == outWarehouseTypeEnum.replaceSendWarehouseEntry
      ) {
        newColumns = columns.filter((x) => x.dataIndex != "lableServe");
      } else if (outWarehouseType == outWarehouseTypeEnum.destruction) {
        newColumns = columns.filter(
          (x) => x.dataIndex != "lableServe" && x.dataIndex != "labelDispose"
        );
      } else if (
        outWarehouseType == outWarehouseTypeEnum.amazonFba ||
        outWarehouseType == outWarehouseTypeEnum.mercadolibre ||
        outWarehouseType == outWarehouseTypeEnum.otherAddress
      ) {
        newColumns = columns.filter((x) => x.dataIndex != "labelDispose");
      }
      state.column = newColumns;
    };

    const handelSetGlobalLabelDispose = (value) => {
      if (value == labelDisposeEnum.userLabelling) {
        state.plan.tableList.forEach(
          (x) => (x.selectedLabelDispose = labelDisposeEnum.userLabelling)
        );
      } else if (value == labelDisposeEnum.warehouseLabelling) {
        state.plan.tableList.forEach(
          (x) => (x.selectedLabelDispose = labelDisposeEnum.warehouseLabelling)
        );
      }
    };

    const handleUpdate = (file) => {
      const isJpgOrPng =
        file.file.type === "image/jpeg" ||
        file.file.type === "image/png" ||
        file.file.type === "image/jpg" ||
        file.file.type === "application/pdf";
      if (!isJpgOrPng) {
        message.error(i18n.t("common.uploaded_img_file_format_incorrect"));
        return false;
      }

      let rowData = file.data.rowData;
      rowData.uploadLoading = true;
      rowData.lableImgSrc = "";
      let parameter = new FormData();
      parameter.append("file", file.file);
      parameter.append("functionModule", 4);
      uploadImg(parameter)
        .then((res) => {
          rowData.lableImgSrc = res.result[0]?.url;
          rowData.lableImgCode = res.result[0]?.parsingCode;
        })
        .catch(() => {
          rowData.lableImgSrc = "";
          rowData.lableImgCode = null;
        })
        .finally(() => {
          rowData.uploadLoading = false;
        });
    };

    //----------------添加箱号  start-----------------
    const handleShowModalAddBoxNo = async () => {
      state.isShowModalAddBoxNo = true;
      funcGetBoxList();
    };

    const handlePage = async (pageData) => {
      state.pageData.skipCount = pageData.skipCount;
      state.pageData.maxResultCount = pageData.maxResultCount;
      funcGetBoxList();
    };

    const hanldeSearchBoxList = () => {
      state.pageData.skipCount = 0;
      state.pageData.currentIndex = 1;
      funcGetBoxList();
    };

    const funcGetBoxList = () => {
      state.modalListLoading = true;
      let beginTime, endTime;
      if (state.addBoxNoModal.dataRange?.length > 0) {
        beginTime = currentTimeToUtc(
          state.addBoxNoModal.dataRange[0]?.format("YYYY-MM-DD 00:00:00")
        );
        endTime = currentTimeToUtc(
          state.addBoxNoModal.dataRange[1]?.format("YYYY-MM-DD 00:00:00")
        );
      }
      const pra = {
        productId: refSearchProductForAddBox.value
          ? refSearchProductForAddBox.value.selectedProductId
          : "",
        boxNo: state.addBoxNoModal.searchBoxKey,
        beginTime: beginTime,
        endTime: endTime,
        // isNeedProduct: true,
        warehouseId: state.plan.inventoryWarehouseAddress.warehouseId,
        ...state.pageData,
      };
      getBoxList(pra)
        .then((res) => {
          if (res.result) {
            let { items, totalCount } = res.result;
            if (items && items.length > 0) {
              state.addBoxNoModal.boxList = items;
              state.pageData.totalCount = parseInt(totalCount);
            } else {
              state.pageData.totalCount = 0;
              state.addBoxNoModal.boxList = [];
            }
          }
        })
        .finally(() => {
          state.modalListLoading = false;
        });
    };

    const handleAddBoxNoToTable = async (record) => {
      let newData = state.addBoxNoModal.boxList.find((x) => x.id == record.id);
      if (newData) {
        newData.key = (state.plan.tableList?.length ?? 0) + 1;
        newData.uploadLoading = false;
        state.plan.tableList.push(newData);
      }
      message.success(i18n.t("common.add_success"));
    };

    const handleClickProductIoc = (record) => {
      state.addBoxNoModal.productDetail.boxNo = record.boxNo;
      state.addBoxNoModal.productDetail.whboxId = record.id;
      state.addBoxNoModal.productDetail.visible = true;
    };

    //----------------添加箱号  end-----------------

    //----------------完善产品数据  start-----------------
    const addProductModalColumns = [
      {
        title: () => i18n.t("warehouse.product_info"),
        slots: {
          customRender: "product_info",
        },
        width: 150,
      },

      {
        title: () => i18n.t("common.count"),
        slots: {
          customRender: "number",
        },
        width: 120,
      },

      {
        title: "",
        slots: {
          customRender: "remove",
        },
        fixed: "right",
        width: 80,
      },
    ];

    const addProductModal = reactive({
      visible: false,
      loading: false,
      record: null,
      productData: null,
      list: [],
    });

    const handleShowAddProductModal = (record) => {
      addProductModal.visible = true;
      addProductModal.record = record;
      addProductModal.list = [];
    };

    const handleAdd = () => {
      if (
        addProductModal.list?.findIndex(
          (x) => x.id == addProductModal.productData.id
        ) >= 0
      ) {
        return message.error(i18n.t("warehouse.product_already_exists"));
      }
      addProductModal.productData.containerCount = null;
      addProductModal.list?.push(addProductModal.productData);
      addProductModal.productData = null;
      refSearchProduct.value.searchProductKey = null;
      refSearchProduct.value.oldSearchKey = null;
    };

    const handleAddProductModalRemove = (record) => {
      addProductModal.list = addProductModal.list.filter(
        (x) => x.id != record.id
      );
    };

    const handleConfirmAddProductModal = () => {
      if (addProductModal.list?.length == 0) {
        return message.error(
          i18n.t("common.p0_is_required", [i18n.t("warehouse.product")])
        );
      }

      if (addProductModal.list.find((x) => !x.containerCount)) {
        return message.error(
          i18n.t("common.p0_is_required", [i18n.t("common.count")])
        );
      }

      addProductModal.loading = true;
      let count = 0;
      let items = addProductModal.list?.map((x) => {
        count = count + x.containerCount;
        return {
          productId: x.id,
          containerCount: x.containerCount,
        };
      });
      addProductToBox(
        addProductModal.record.id,
        state.plan.inventoryWarehouseAddress.warehouseId,
        items
      )
        .then(({ result }) => {
          if (result) {
            addProductModal.visible = false;
            addProductModal.record.productRls = addProductModal.list;
            if (addProductModal.list?.length == 1) {
              addProductModal.record.productKindCount = 1;
            } else if (addProductModal.list?.length > 1) {
              addProductModal.record.productKindCount =
                addProductModal.list?.findIndex(
                  (x) => x.id != addProductModal.list[0]?.id
                ) > 0
                  ? 2
                  : 1;
            }
            addProductModal.record.containerCount = count;
          }
          addProductModal.loading = false;
        })
        .catch((e) => {
          // console.log(e);
          addProductModal.loading = false;
        });
    };

    //----------------完善产品数据  end-----------------

    //----------------批量上传箱标  start-----------------
    const handleBatchUploadLabels = (file) => {
      if (file?.file?.size > maxUploadFileSizeByte()) {
        message.error(
          i18n.t("common.please_upload_file_with_size_less_than_50MB")
        );
        return;
      }

      state.loading = true;
      let parameter = new FormData();
      parameter.append("file", file.file);
      parameter.append("boxNum", state.plan?.tableList?.length);
      batchUploadLabels(parameter)
        .then(({ result }) => {
          state.plan?.tableList.forEach((x, index) => {
            if (index < result.length) {
              x.lableImgSrc = result[index].url;
              x.lableImgCode = result[index].parsingCode;
            } else {
              x.lableImgSrc = "";
              x.lableImgCode = null;
            }
          });
          // _mapList(result);
          state.loading = false;
        })
        .catch(() => {
          state.plan?.tableList?.forEach((x, index) => {
            x.lableImgSrc = "";
            x.lableImgCode = null;
          });
          state.loading = false;
        });
    };
    //----------------批量上传箱标  end-----------------

    onMounted(async () => {
      getCacheData()
        .then(() => {
          getColumn();
        })
        .then(() => {
          funcHitTorrServes();
        });
    });

    onBeforeRouteLeave((to, from, next) => {
      const cacheRoutes = ["/storage/selectoutWay", "/storage/addboxinfo"];
      if (!cacheRoutes.includes(to.path)) {
        let tabsHasOrderRoute =
          visitedRoutes.value.findIndex((x) => cacheRoutes.includes(x.path)) >
          -1;
        if (!tabsHasOrderRoute) {
          removeObjectSessionStorage(outPlanKeys.createOutPlanData);
        }
      }
      next();
    });

    const currentPageIndex = ref(1);
    const defaultPageSize = ref(50);
    return {
      currentPageIndex,
      defaultPageSize,

      outWarehouseTypeEnum,
      labelDisposeEnum,

      modalColumns,
      refSearchProductForAddBox,
      refSearchProduct,
      refInputAddPlanName,
      ...toRefs(state),

      getAddress,
      getLinkman,

      handleShowAddNameModal,
      handleFocusSelectValue,

      handleBack,

      handleShowModalAddBoxNo,
      funcGetBoxList,
      handleAddBoxNoToTable,
      handleClickProductIoc,

      handleDelete,
      handleCreatePlan,

      handlePage,
      handelSetGlobalLabelDispose,
      isExistsBox,
      handleUpdate,
      hanldeSearchBoxList,

      innerColumns,
      gToKg,

      addProductModalColumns,
      addProductModal,
      handleShowAddProductModal,
      handleConfirmAddProductModal,
      handleAdd,
      handleAddProductModalRemove,

      handleBatchUploadLabels,
    };
  },
};
</script>

<style lang="less" scoped>
.pabi-img {
  max-width: 54px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  :deep(img) {
    max-height: 54px;
    max-width: 54px;
  }
}
.label-serve {
  min-height: 110px;
  :deep(.ant-upload.ant-upload-select-picture-card) {
    height: 54px;
    width: 54px;
    margin-top: 4px;
    margin-bottom: 0px;
  }

  :deep(.ant-upload) {
    padding: 0px;
  }
}
</style>
